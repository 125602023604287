Vue.component("material-select", {
    template: `<select class="icons" @keydown.prevent="" @keydown.prevent="" @keyup.prevent="" >
                    <option value="" >{{tr('Select One')}}</option>
                    <option :value="option.value" v-for="option in options">{{tr(option.label)}}</option>
                </select>`,
    props: ['value','label','options'],
    watch: {
        value: function (value) {
            this.reload(value);
        }
    },
    methods:{
        reload : function (value) {
            var select = $(this.$el);
            select.val(value || this.value);
            select.material_select('destroy');
            select.material_select();
        }
    },
    mounted: function () {
        var vm = this;
        var select = $(this.$el);
        select.val(this.value)
            .on('change', function () {
                console.log('Test Event');
                vm.$emit('input', this.value);
            });
        select.material_select();
    },
    updated: function () {
        this.reload();
    },
    destroyed: function () {
        $(this.$el).material_select('destroy');
    }
});

Vue.component('date-input' ,{
    props: ['value','label'],
    template: `<div class="input-field oo-date-field ">
                    <input :id="id" ref="input" type="text" :value="internalValue" @change="onInput">
                    <span @click="openCalendar" class="input-icon"></span>
                    <label :for="id" ><span v-if="label">{{tr(label)}}</span></label>
               </div>`,
    methods: {
        onInput: function (e) {
            this.internalValue = e.target.value
            this.$emit("input", e.target.value? e.target.value : null)
        },
        openCalendar() {
            this.fp.open()
        }

    },
    mounted: async function () {
        let self = this;

        let cnf = {
            //onChange: function (selectedDates, dateStr, instance) { /*fp.triggerOnChange(selectedDates, dateStr, instance)*/ },
            disableMobile: true, allowInput: true, dateFormat: 'd/m/Y', clickOpens: false}

        /*this.fp = flatpickr(`#${this.id}`, cnf);
        $(this.$refs.input).mask('00/00/0000')
        this.$refs.input.addEventListener("keyup", function (ev) {
            //console.log("KEYUP", ev.key)
            if (ev.key === 'ArrowDown') {
                self.fp.open();
            }
        })*/
        await Vue.nextTick();
        //Materialize.updateTextField($(this.$refs.input))
    },
    data: function () {
        return {

            fp: null,
            id: this.getIdo.ui.genId(),
            internalValue: this.value? moment(this.value).format("DD/MM/YYYY") : ''
        }
    },
    watch: {
        value: function (val) {
            //console.log("VAL", val)
            this.internalValue = val? moment(val).format("DD/MM/YYYY") : ''
            this.fp.setDate(this.internalValue)
            //$(this.$refs.input).val(moment(val).format("DD/MM/YYYY"))
        }
    }
});

Vue.component('input-hide', {
    props: ['def', 'value', 'custom_css_classes'],
    data: function () {
        return {
            isSet: false,
        }
    },
    computed:{
        id:function () {
            return this.getUI()
        },
        getDef: function () {
            let defaults = {visibility: true, readonly: false, pastewindow: null, fillpastewindow: null}
            Object.assign(defaults, this.def)
            return defaults;
        },
    },
    methods: {
        focus() {
            this.$refs.input.focus()
        },
    },
    template: `<input :disabled="getDef.readonly" type="hidden" :id="id" placeholder="" :value="value" @input="$emit('input',$event)" ref="input" >`,
});

Vue.component('period-component',{
    props:{
        'param':{
            type:Object,
            default:{},
        },
        'field':String,
        'value': String,
        "description":Object,
        'callback': Function
    },
    data:function() {
        return {
            date: moment(),
            from_id: this.getUI(),
            to_id: this.getUI(),
            lastx_id: this.getUI(),
        }
    },
    template:`<div> 
                 <>                  
                 <input @input="setDaySelector()" type="date" class="col s4" :label="from_label" v-model="param[from]"></input>
                 <input @input="setDaySelector()" type="date" class="col s4" :label="to_label" v-model="param[to]"></input>
              </div>`,
    methods: {
        setDaySelector: function () {

            if ((this.record[this.from] || this.record[this.to]) && !this.record[this.selector]) {

                this.$nextTick(() => {
                    this.record[this.selector] = "9999"
                })

            }

        },
        change_selector: function (value) {
            if (value && value.startsWith("LASTX")) {
                this.$nextTick(() => {
                    this.$refs.input_last.focus()
                    Materialize.updateTextFields()
                })
            }
        }
    },
    computed: {
        field: function () {
            return this.description.field
        },
        last_x: function () {
            return this.field + "_lastXcount_"
        },
        selector: function () {
            return this.field + "_selector_"
        },
        from: function () {
            return this.field + "_from_"
        },
        to: function () {
            return this.field + "_to_"
        },
        label: function () {
            return this.description.label ? this.description.label : this.description.field
        },
        last_x_label: function () {
            return `${this.tr("X Value")} - ${this.tr(this.label)}`
        },
        from_label: function () {
            return `${this.tr("From")} - ${this.tr(this.label)}`
        },
        to_label: function () {
            return `${this.tr("To")} - ${this.tr(this.label)}`
        },
        customClass: function () {
            var classes = "oo-select-field col";
            if (this.hide_days && this.hide_lastX) {
                classes += " s12"
            } else if (this.hide_days && !this.hide_lastX) {
                classes += " s8"
            } else if (!this.hide_days && this.hide_lastX) {
                classes += " s4"
            } else {
                classes += " s4"
            }
            return classes;
        }
    },
    /*components() {
        return {
            'date-input': cm.DateInputComponent.getVueComponent(),
            "input-component": cm.InputComponent.getVueComponent()
        }
    }*/
});

Vue.component('input-component',{
    props: ['def', 'value', 'custom_css_classes'],
    data: function () {
        return {
            isSet: false,
        }
    },
    watch: {},
    computed: {

        css_class: function () {

            let default_css = {
                'col s12': true,
                'input-field ': (this.editor != 'checkbox' &&  this.editor != 'vuecomponent' && this.editor != 'matrix'),
            }

            return this.custom_css_classes || default_css;

        },
        getDef: function () {
            let defaults = {visibility: true, readonly: false, pastewindow: null, fillpastewindow: null}
            Object.assign(defaults, this.def)
            return defaults;
        },
        label: function () {
            if (this.isSet) {
                return this.tr(this.getDef.label) + "  (a,b)"
            }
            return this.getDef.label

        },
        editor: function () {
            this.isSet = false;
            switch (this.getDef.editor || this.getDef.type) {
                case 'string':
                    return 'string';
                case 'boolean':
                case 'checkbox':
                    return 'checkbox';
                case 'combobox':
                    return 'combobox';
                case 'period':
                    return 'period';
                case 'date':
                    return 'date';
                case 'matrix':
                    return 'matrix';
                case 'datepicker':
                    return 'date';
                case 'set':
                    this.isSet = true;
                    return "string";
                case 'vuecomponent':
                    if(this.getDef.component=="PeriodComponent")
                        return "period";
            }
            return this.getDef.type
        }
    },
    methods: {
        focus() {
            this.$refs.input.focus()
        },
        fpw: function () {
            return this.getDef.fillpastewindow ? this.getDef.fillpastewindow.bind(this.cardcomponent)() : 'NOHABILITADO'
        }
    },
    mounted: function () {
        /*if (this.editor == 'combobox')
            (this.$refs.input).material_select()*/
    },
    updated: function () {
        //Materialize.updateTextField($(this.$refs.input))
        /*if (this.editor == 'combobox')
            $(this.$refs.input).material_select()*/
    },
    template: `<div v-bind:class="css_class" ref="div" >
                <label class="active" v-if="editor != 'period' & editor != 'checkbox' " :for="id">{{tr(label)}}</label>        
                <input :disabled="getDef.readonly" :label="label" v-if="editor == 'string'"  type="text" :id="id" placeholder="" :value="value" @input="$emit('input',$event)" ref="input" >
                <input :disabled="getDef.readonly" v-else-if="editor == 'checkbox'" type="checkbox" :id="id" v-bind:checked="value" @change="$emit('input', $refs.input.checked)" ref="input">
                <input :disabled="getDef.readonly" v-else-if="editor == 'date'" type="date" :id="id"  @change="$emit('input', $refs.input.value)" ref="input">
                <material-select  :options="getDef.options" :disabled="getDef.readonly"  v-else-if="editor == 'combobox'" :id="id"  :value="value" @change="$emit('input', $refs.input.value)" ref="input" />
                <period-component :description="getDef" v-else-if="editor == 'period'" class="card-reveal-select" :value="value"  ref="input" @change="$emit('input', $event);" ></period-component>               
        </div>`,
});

Vue.component('genericReportForm',{
    props:['reportScope','reportName',"loading"],
    data:function(){
        let today = moment();
        return {
            running:false,
            selected:null,
            reportParams:{},
            id: this.getUI(),
        }
    },
    created:function(){
        this.createInitialParameters();
        this.loadReportParams();
    },
    mounted:function(){
        //Materialize.updateTextField($(this.$refs.input));
    },
    updated:function(){
        //Materialize.updateTextField($(this.$refs.input));
        this.loadReportParams();
    },
    computed:{
        params:function () {
            return this.loadReportParams()
        }
    },
    methods:{
        createInitialParameters(){
            let reportParams = {}
            let reportSpec = Object.values(this.$store.getters.getReportParameters(this.reportName));
            //console.log('Report Spec Parameters',reportSpec);
            for(let param of reportSpec){
                if(param.fieldDef.component!='PeriodComponent'){
                    reportParams[param.field]= this.getValue(param.default);
                }
                else{
                    let periodConfig = this.getPeriodDate(param.default);
                    reportParams[param.field+'_from_']= periodConfig[0];
                    reportParams[param.field+'_to_']  = periodConfig[1];
                    reportParams[param.field+'_selector_']="9999";
                }
            }
            this.reportParams=reportParams;
        },
        getEditor: function (param) {
            this.isSet = false;
            console.log(param,param.fieldDef.editor || param.fieldDef.type);
            switch (param.fieldDef.editor || param.fieldDef.type) {
                case 'string':
                    return 'string';
                case 'boolean':
                case 'checkbox':
                    return 'checkbox';
                case 'combobox':
                    return 'combobox';
                case 'period':
                    return 'period';
                case 'date':
                    return 'date';
                case 'matrix':
                    return 'matrix';
                case 'datepicker':
                    return 'date';
                case 'set':
                    return "string";
                case 'vuecomponent':
                    if(param.fieldDef.component=="PeriodComponent")
                        return "period";
            }
            return param.getDef.type
        },
        getValue:function(defaultValue){
            switch (defaultValue) {
                case "@Customer.GroupCode":
                    return this.$store.getters.getCustomer.GroupCode || '';
                case "@Customer.Gifts":
                    return this.$store.state.customerGifts.join(",");
             }
            return defaultValue;
        },
        getPeriodDate:function(defaultValue){
            let fromPeriod =moment().subtract(1, 'months').format('YYYY-MM-DD');
            let toPeriod = moment().format('YYYY-MM-DD');
            console.log('Calculate Date Default',defaultValue)
            if(defaultValue){
                let extracValue = defaultValue.match(/(\d+)/);
                switch (true) {
                    case /@LastMonth/.test(defaultValue):
                        fromPeriod =moment().subtract(1, 'months').format('YYYY-MM-DD');
                        toPeriod = moment().format('YYYY-MM-DD');
                        break;
                    case /@LastWeek/.test(defaultValue):
                        fromPeriod =moment().subtract(1, 'weeks').format('YYYY-MM-DD');
                        toPeriod = moment().format('YYYY-MM-DD');
                        break;
                    case /^@Last[0-9]*Year$/.test(defaultValue):
                    case /^@Last[0-9]*Years$/.test(defaultValue):
                        let months = 1;
                        if(extracValue)
                            months=extracValue[0];
                        fromPeriod =moment().subtract(months , 'years').format('YYYY-MM-DD');
                        toPeriod = moment().format('YYYY-MM-DD');
                        break;
                    case /^@Last[0-9]*Month$/.test(defaultValue):
                    case /^@Last[0-9]*Months$/.test(defaultValue):
                        let years = 1;
                        if(extracValue)
                            years=extracValue[0]
                        fromPeriod =moment().subtract(years, 'months').format('YYYY-MM-DD');
                        toPeriod = moment().format('YYYY-MM-DD');
                        break;
                    case /^@Last[0-9]*Day$/.test(defaultValue):
                    case /^@Last[0-9]*Days$/.test(defaultValue):
                        let days = 1;
                        if(extracValue)
                            days=extracValue[0]
                        fromPeriod =moment().subtract(days, 'days').format('YYYY-MM-DD');
                        toPeriod = moment().format('YYYY-MM-DD');
                        break;
                }
            }
            return [fromPeriod,toPeriod];
        },
        getReportName(){
            let splitReportName = this.reportName.split("_")
            return splitReportName[0]
        },
        loadReportParams(){
            console.log('loadReportParams',this.$store.getters.getReportParameters(this.reportName));
            return this.$store.getters.getReportParameters(this.reportName);
        },
        runReport(){
          console.log('run with params',this.reportParams);
          let self = this;
          this.running=true;
          this.reportScope.runAction(this.reportParams)
              .then(function(result){
                  self.running=false;
              })
        },
        runExport(){
            console.log('export with params',this.reportParams);
            let self = this;
            this.running=true;
            this.reportScope.exportAction(this.reportParams).then(function(result){
                self.running=false;
            });
        }
    },
    template:`<div :key="'report-form'+reportName"> 
                 <h3>{{tr($store.getters.getReportTitle(reportName))}}</h3>
                 <div class="row">   
                     <template v-for="param of params">
                        <template v-if="param.isEditable">
                            <template v-if="param.fieldDef.component!='PeriodComponent'">
                                <div class="col s3" > 
                                    <label class="active" v-if="getEditor(param) != 'period' & getEditor(param) != 'checkbox' " :for="param.field+'_'+id">{{tr(param.fieldDef.label)}}</label>        
                                    <input  v-if="getEditor(param) == 'string'"  :id="param.field+'_'+id" type="text"  v-model="reportParams[param.field]" placeholder="" :value="getValue(param.default)" @input="$emit('input',$event)" ref="input" >
                                    <input  v-else-if="getEditor(param) == 'checkbox'" type="checkbox" :id="param.field+'_'+id" v-bind:checked="getValue(param.default)" @change="$emit('input', $refs.input.checked)" ref="input">
                                    <input  v-else-if="getEditor(param) == 'date'"  type="date" :id="param.field+'_'+id"  v-model="reportParams[param.field]" ref="input">
                                    <material-select  :options="param.fieldDef.options" v-else-if="getEditor(param) == 'combobox'" :id="param.field+'_'+id"  :value="getValue(param.default)" @change="$emit('input', $refs.input.value)" ref="input"  v-model="reportParams[param.field]"/> 
                                </div>
                            </template>
                            <template v-else>
                                 <div class="col s3" > 
                                    <label class="active"  :for="param.field+'_from_'+id">{{tr("From")}} {{tr(param.fieldDef.label)}}</label>        
                                    <input :id="param.field+'_from_'+id" type="date" v-model="reportParams[param.field+'_from_']" t placeholder="" :value="getPeriodDate(param.default)" @input="$emit('input',$event)" ref="input" > 
                                </div>
                                <div class="col s3" > 
                                    <label class="active"  :for="param.field+'_to_'+id">{{tr("To")}} {{tr(param.fieldDef.label)}}</label>        
                                    <input :id="param.field+'_to_'+id"  v-model="reportParams[param.field+'_to_']" type="date"  placeholder="" :value="getPeriodDate(param.default,)" @input="$emit('input',$event)" ref="input" > 
                                </div>
                            </template>
                        </template> 
                        <template v-else>
                             <input-hide :def="param.fieldDef" :value="getValue(param.default)" />
                        </template>
                     </template>
                     <div class="row col" v-bind:class="{s3:getReportName()!='StockList',s12:getReportName()=='StockList'}">
                        <div class="col" v-bind:class="{disabled:running,s12:getReportName()!='StockList',s4:getReportName()=='StockList'}">
                            <label></label>
                            <button class="btn col s12" v-bind:class="{disabled:running}" @click="runReport">{{tr('Run')}}</button>
                        </div>
                        <template v-if="reportName!='StockList'">
                            <hr/>
                        </template>
                        <div class="col" v-bind:class="{disabled:running,s12:getReportName()!='StockList',s4:getReportName()=='StockList'}">
                            <label></label>
                            <button class="btn col s12" v-bind:class="{disabled:running}" @click="runExport">{{tr('Export')}}</button>
                        </div>
                     </div>
                 </div>
            </div>`
});